import React from 'react';
import { Image, Row, Col, Container, Card } from 'react-bootstrap';
import { TikTokEmbed } from 'react-social-media-embed';
import { InstagramEmbed } from 'react-social-media-embed';

const SocialMedia = () => {
	return (
		<div className='bg-white p-3'>
			<Row>
				<Col xs={12} md={6} className='d-flex align-items-center'>
					<Card className='border-0 rounded-0 mx-auto' style={{}}>
						<Card.Body className='mx-auto border-0'>
							<TikTokEmbed
								url='https://www.tiktok.com/@jamesdavidpresents/video/7204628837219470638?is_from_webapp=1'
								width={325}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} md={6} className='d-flex align-items-center'>
					<Card className='border-0 rounded-0 mx-auto' style={{}}>
						<Card.Body className='mx-auto border-0'>
							<InstagramEmbed
								url='https://www.instagram.com/p/CgwijpHB0fH/'
								width={328}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default SocialMedia;

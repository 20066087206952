import React from 'react';
import { Card, Container, CardGroup, ListGroup } from 'react-bootstrap';
import { GiPalmTree, GiLinkedRings, GiGolfFlag } from 'react-icons/gi';
import { FaWineBottle } from 'react-icons/fa';

const Offerings = () => {
	return (
		<Container fluid className='bg-white g-0 px-0'>
			<Card className='border-0 rounded-0 p-2'>
				<Card.Body className=''>
					<Card.Title className='text-center display-4 p-2 fw-lighter text-green '>
						The Best in Entertainment
					</Card.Title>
				</Card.Body>
				<CardGroup className='text-green'>
					<Card
						className='border-0 rounded-0 p-0  '
						style={{ minWidth: '15rem', height: '10rem' }}>
						<Card.Body className='p-0 text-center  '>
							<div className='p-2 display-4'>
								<GiLinkedRings />
							</div>
							<Card.Title className='fs-4 fw-lighter'>
								WEDDINGS
							</Card.Title>
						</Card.Body>
					</Card>
					<Card
						className='border-0 rounded-0 p-0   '
						style={{ minWidth: '15rem', height: '10rem' }}>
						<Card.Body className='p-0 text-center'>
							<div className='p-2 display-4'>
								<GiGolfFlag />
							</div>
							<Card.Title className='fs-4 fw-lighter'>
								COUNTRY CLUBS
							</Card.Title>
						</Card.Body>
					</Card>
					<Card
						className='border-0 rounded-0 p-0   '
						style={{ minWidth: '15rem', height: '10rem' }}>
						<Card.Body className='p-0 text-center '>
							<div className='p-2 display-4'>
								<FaWineBottle />
							</div>
							<Card.Title className='fs-4 fw-lighter'>
								PRIVATE EVENTS
							</Card.Title>
						</Card.Body>
					</Card>
					<Card
						className='border-0 rounded-0 p-0   '
						style={{ minWidth: '15rem', height: '10rem' }}>
						<Card.Body className='p-0 text-center '>
							<div className='p-2 display-4'>
								<GiPalmTree />
							</div>
							<Card.Title className='fs-4 fw-lighter'>
								RETIREMENT COMMUNITIES
							</Card.Title>
						</Card.Body>
					</Card>
				</CardGroup>
			</Card>
			{/* <div class='show-wrapper d-flex align-items-center justify-content-center position-relative flex-wrap text-green pt-5 bg- white'>
				<div className='show d-flex position-relative flex-column '>
					<div className='imgShow-wrapper'>
						<GiLinkedRings className=' display-2 pb-1 ' />
						<div className='fs-5 fw-lighter text-center p-2'>
							WEDDINGS
						</div>
					</div>

					<div className='content'>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing
							elit. Suspendisse eget velit tristique, sollicitudin
							leo viverra, suscipit neque. Aliquam ut facilisis
							urna, in pretium nibh. Morbi in leo in eros commodo
							volutpat ac sed dolor.
						</p>
					</div>
				</div>
				<div className='show d-flex position-relative flex-column'>
					<div className='imgShow-wrapper'>
						<GiGolfFlag className=' display-2 pb-1 ' />
						<div className='fs-5 fw-lighter text-center p-2'>
							COUNTRY CLUBS
						</div>
					</div>
					<div className='content'>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing
							elit. Suspendisse eget velit tristique, sollicitudin
							leo viverra, suscipit neque. Aliquam ut facilisis
							urna, in pretium nibh. Morbi in leo in eros commodo
							volutpat ac sed dolor.
						</p>
					</div>
				</div>
				<div className='show d-flex position-relative flex-column'>
					<div className='imgShow-wrapper'>
						<FaWineBottle className=' display-2 pb-1 ' />
						<div className='fs-5 fw-lighter text-center p-2'>
							EVENTS
						</div>
					</div>

					<div className='content'>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing
							elit. Suspendisse eget velit tristique, sollicitudin
							leo viverra, suscipit neque. Aliquam ut facilisis
							urna, in pretium nibh. Morbi in leo in eros commodo
							volutpat ac sed dolor.
						</p>
					</div>
				</div>{' '}
				<div className='show d-flex position-relative flex-column'>
					<div className='imgShow-wrapper'>
						<GiPalmTree className=' display-2 pb-1 ' />
						<div className='fs-5 fw-lighter text-center p-2'>
							RETIREMENT COMMUNITIES
						</div>
					</div>
					<div className='content'>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing
							elit. Suspendisse eget velit tristique, sollicitudin
							leo viverra, suscipit neque. Aliquam ut facilisis
							urna, in pretium nibh. Morbi in leo in eros commodo
							volutpat ac sed dolor.
						</p>
					</div>
				</div>
			</div> */}
		</Container>
	);
};

export default Offerings;

import React, { Component } from 'react';
import './Hero.css';
import BackgroundVideo from '../../assets/BackgroundVideo.mp4';
import { Button, Image } from 'react-bootstrap';
import logo from '../../assets/WHITE_HORIZ.png';
import Booking from '../Booking/Booking';

class Hero extends Component {
	render() {
		return (
			<div className='hero-container mt-5'>
				<video src={BackgroundVideo} autoPlay loop muted />
				<div className=''>
					{' '}
					<Image src={logo} fluid />
				</div>
				<div>
					<Booking />
				</div>
			</div>
		);
	}
}

export default Hero;

import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Container, Card } from 'react-bootstrap';
import TestimonialCard from '../Testimonials/TestimonialCard';

const CarouselComp = () => {
	const [index, setIndex] = useState(0);

	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};

	return (
		<Container fluid className='m-0 g-0 bg-white pt-5' id='testimonials'>
			<Carousel
				variant='light'
				activeIndex={index}
				onSelect={handleSelect}
				hover
				interval={10000}
				controls>
				<Carousel.Item className='bg-tux text-white '>
					<Container fluid className=' p-5'>
						<TestimonialCard
							className=''
							alt='John Colianni '
							quote="As a long time Pianist/Conductor to numerous big-name singers, including Mel Torme, I give a high ranking to Jim Cargill on my list of modern day Jazz Crooners. I first heard him on his singing waiter job in New York City, and he always knocked everyone out with his smooth delivery and powerful voice.
						Since then. I've had the good fortune to accompany James on several live performances, and he is truly a stand out in today's crop of pop and jazz singers."
							author='John Colianni'
							credit1='Pianist,'
							credit2='Band Leader,'
							credit3='Arranger/Composer'
						/>
					</Container>
				</Carousel.Item>
				<Carousel.Item className='bg-clubs text-white '>
					<Container fluid className=' p-5'>
						<TestimonialCard
							alt='Michael Berkowitz'
							quote="If we still had night clubs, variety shows on television and 33 1/3 I p's, James would be a major star.
						He sings like Dean, swings like Frank and dances like none of them, but you can't have everything. He's a talented young man and I recommend you see him in person as often as possible."
							author='Michael Berkowitz'
							credit1='Conductor/Drummer/Music Director,'
							credit2='Principal Pops Conductor - Santa Rosa Symphony'
						/>
					</Container>
				</Carousel.Item>
				<Carousel.Item className='bg-action text-white '>
					<Container fluid className=' p-5'>
						<TestimonialCard
							alt='Matt Deangelis'
							quote="James David is a complete and engaging performer. 20 years in show business and it's hard to find a more polished professional than James. From pop to frank sinatra he does it all. Charming and beyond gifted.
						James David is a star."
							author='Matt Deangelis'
							credit2='Waitress (Broadway),'
							credit3='Hair (Broadway)'
						/>
					</Container>
				</Carousel.Item>
				<Carousel.Item className='bg-iridium text-white '>
					<Container fluid className=' p-5'>
						<TestimonialCard
							alt='Mimi Summers'
							quote='James was referred to me by one of my residents. Her son works with James and she met him when he performed at another venue. James was a HUGE hit with everyone here! He is not only a great singer, he is a true entertainer. His interaction with the audience, makes all the difference. I will definitely book him back soon. A true treasure of entertainment!'
							author='Mimi Summers'
							credit1='Lifestyle Director, Atwater at Nocatee'
						/>
					</Container>
				</Carousel.Item>
				<Carousel.Item className='bg-ymca text-white '>
					<Container fluid className=' p-5'>
						<TestimonialCard
							alt='Eric Robinson'
							quote="Over the many years I've worked with James, he has consistently wowed audiences with his complete master of the sounds, styles and textures of his extensive vocal library. However, something even more impactful shines through in each of his captivating performances: his obvious passion, love and respect for these timeless songs beget a rare kind of effortlessness that leaves his listeners entranced. Add all that to a charming and affable personality and a pristine sense of professionalism - and it quickly becomes clear that, in his genre, James is easily among the top tier talents in the nation."
							author='Eric Robinson'
							credit1='Producer/Actor/Teacher/Director/Comedian,'
							credit2='Creator - BATSU!,'
							credit3='Founder - CFO - Producer Face Off Unlimited'
						/>
					</Container>
				</Carousel.Item>
			</Carousel>
		</Container>
	);
};

export default CarouselComp;

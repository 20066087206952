import React from 'react';
import { Container, Card } from 'react-bootstrap';

const Spacer = (props) => {
	return (
		<Container
			className='bg-white m-0 display-6 d-flex align-items-center justify-content-center'
			fluid
			style={{ height: '100px' }}></Container>
	);
};

export default Spacer;

import React from 'react'; // Import the Component component from React
import { Card } from 'react-bootstrap';
import { Image, Row, Col, Container } from 'react-bootstrap';
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri';

function TestimonialCard(props) {
	return (
		<>
			<Card
				className='py-5 mx-auto border-0 bg-transparent border-0 rounded-0'
				style={{}}>
				<div className='text-center'>
					{/* <Image
						className='mx-auto '
						// style={{ backgroundColor: 'var(--dark)' }}
						src={props.image}
						alt={props.alt}
						style={{ width: '8rem' }}
						thumbnail
					/> */}

					<Card.Text
						className='blockquote card-body text-start mx-auto p-3'
						style={{ maxWidth: '1200px' }}>
						<div className='position-relative'>
							<span className='fs-4 d-flex justify-content-start'>
								<RiDoubleQuotesL />
							</span>{' '}
							<br />
							<div className='fs-6 fw-lighter p-2 m-0 '>
								{props.quote}
							</div>{' '}
							<br />
							<br />
							<span className='fs-4 d-flex justify-content-end'>
								<RiDoubleQuotesR />
							</span>{' '}
						</div>

						<footer className='blockquote-footer text-white'>
							<small className='fs-6'>
								{props.author}
								<br />
								<br />
								<span title=''>{props.credit1} </span>
								<span title=''>{props.credit2} </span>
								<span title=''>{props.credit3}</span>
							</small>
						</footer>
					</Card.Text>
				</div>
			</Card>
		</>
	);
}

export default TestimonialCard;

import { Container, Card, Button } from 'react-bootstrap';
import NocateeCard from '../components/NocateeCard/NocateeCard';

import CarouselComp from '../components/Carousel/CarouselComp';
import Hero from '../components/Hero/Hero';
import VideoComponent from '../components/Video/VideoComponent';
import About from '../components/About/About';
import Offerings from '../components/Offerings/Offerings';

import Spacer from '../components/Spacer/Spacer';

import Calendar from '../components/Calendar/Calendar';
import { Link } from 'react-router-dom';
import SocialMedia from '../components/SocialMedia/SocialMedia';

function Home() {
	return (
		<>
			<Hero />
			<Offerings />
			<Spacer />

			<NocateeCard />
			<Spacer />
			<VideoComponent />
			<SocialMedia />
			<Spacer />

			<About />

			<Spacer />
			<CarouselComp />
			<Calendar />
		</>
	);
}

export default Home;

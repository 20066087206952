import React from 'react';
import {
	Navbar,
	NavDropdown,
	Nav,
	Container,
	Image,
	Row,
	Col,
} from 'react-bootstrap';
import logoShort from '../../assets/logo_SHORT_GREEN.png';
import logo from '../../assets/GREEN_HORIZ.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebook,
	faInstagram,
	faTiktok,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const NavbarTop = () => {
	return (
		<Navbar
			bg='white'
			style={{}}
			expand='lg'
			className=' mx-0 p-0 navyBar'
			variant='light'
			fixed='top'>
			<Container className='nav-gradient p-0 g-0' fluid>
				{' '}
				{/* -------------------navLOGO short */}
				<Navbar.Brand href='/' className='p-2 ms-2'>
					<Image src={logoShort} style={{ width: '100px' }} />
				</Navbar.Brand>
				{/* --------------------navLOGO full */}
				{/* <Navbar.Brand href='/' className='p-2 '>
					<Image src={logo} style={{ width: '150px' }} />
				</Navbar.Brand> */}
				<Navbar.Toggle aria-controls='basic-navbar-nav' className='' />
				<Navbar.Collapse id='basic-navbar-nav' className=''>
					<Nav className='mx-auto ps-4'>
						<Nav.Link href='/#about'>About</Nav.Link>
						{/* <Nav.Link href='#link'>Weddings</Nav.Link> */}
						{/* <Nav.Link href='#link'>Country Clubs</Nav.Link> */}
						{/* <Nav.Link href='#link'>Retirement Communities</Nav.Link> */}
						<Nav.Link href='/#media'>Media</Nav.Link>
						<Nav.Link href='#testimonials'>Testimonials</Nav.Link>
						{/* <Nav.Link href='#link'>Shows</Nav.Link> */}

						<Nav.Link href='/#contact'>Contact</Nav.Link>
					</Nav>
					<div className='row'>
						<Nav.Link
							href='mailto:james@jamesdavidpresents.com'
							target='_blank'
							className='col-2 p-3'>
							<FontAwesomeIcon
								className='text-green'
								icon={faEnvelope}
								size='2x'
							/>
						</Nav.Link>
						<Nav.Link
							href='https://instagram.com/jamesdavidpresents?igshid=NzAzN2Q1NTE='
							target='_blank'
							className='col-2 p-3 '>
							<FontAwesomeIcon
								className='text-green'
								icon={faInstagram}
								size='2x'
							/>
						</Nav.Link>
						<Nav.Link
							href='https://www.tiktok.com/@jamesdavidpresents?is_from_webapp=1&sender_device=pc'
							target='_blank'
							className='col-2 p-3'>
							<FontAwesomeIcon
								className='text-green'
								icon={faTiktok}
								size='2x'
							/>
						</Nav.Link>
						<Nav.Link
							href='https://www.facebook.com/memorylnent'
							target='_blank'
							className='col-2 p-3'>
							<FontAwesomeIcon
								className='text-green'
								icon={faFacebook}
								size='2x'
							/>
						</Nav.Link>
						<Nav.Link
							href='https://www.youtube.com/@james_david_presents'
							target='_blank'
							className='col-2 p-3'>
							<FontAwesomeIcon
								className='text-green'
								icon={faYoutube}
								size='2x'
							/>
						</Nav.Link>
					</div>
				</Navbar.Collapse>
			</Container>
		</Navbar>
		// 	<Navbar
		// 	bg='light'
		// 	style={{}}
		// 	expand='lg'
		// 	className='align-items-center mx-0 px-0 '
		// 	variant='light'
		// 	sticky='top'>
		// 	<Container className='nav-gradient g-0 ' fluid>
		// 		{' '}
		// 		<Navbar.Brand href='/' className=''>
		// 			JDP
		// 		</Navbar.Brand>
		// 		<Navbar.Toggle aria-controls='basic-navbar-nav' className='' />
		// 		<Navbar.Collapse id='basic-navbar-nav' className=''>
		// 			<Nav className='mx-auto'>
		// 				<Nav.Link href='#home'>About</Nav.Link>

		// 				<Nav.Link href='#link'>Weddings</Nav.Link>
		// 				<Nav.Link href='#link'>Country Clubs</Nav.Link>
		// 				<Nav.Link href='#link'>Retirement Communities</Nav.Link>
		// 				<Nav.Link href='#link'>Testimonials</Nav.Link>
		// 				<Nav.Link href='#link'>Media</Nav.Link>
		// 			</Nav>
		// 		</Navbar.Collapse>
		// 	</Container>
		// </Navbar>
	);
};

export default NavbarTop;

import React from 'react';
import { Container, Accordion, Card, Col, Row } from 'react-bootstrap';
import jim from '../../assets/jimmySolo.png';
import Booking from '../Booking/Booking';

const Calendar = () => {
	return (
		<Container className='g-0 px-0  bg-white  ' fluid>
			<Container className='px-0 g-0 text-center' fluid>
				<Card
					className='mx-auto border-0 rounded-0 px-0'
					style={{ maxWidth: '450px' }}>
					<Card.Body className='bg-white px-0'>
						<Accordion className='mx-auto px-0'>
							<Accordion.Item eventKey='0'>
								<Accordion.Header className='bg-transparent '>
									<div className='fs-3 fw-light'>
										Upcoming Dates
									</div>
								</Accordion.Header>

								<Accordion.Body>
									<iframe
										src='https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&showTz=1&showTabs=0&showPrint=0&showNav=1&showTitle=0&mode=AGENDA&title&src=bWVtb3J5bG5lbnRAZ21haWwuY29t&color=%23039BE5'
										width='auto'
										height='600'
										frameborder='0'
										scrolling='no'></iframe>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</Card.Body>
				</Card>
			</Container>

			<Card className='border-0  rounded-0 bg-white  '>
				<Row className=' '>
					<Col xs={12} md={6} className='d-none d-md-block'>
						<Card.Img className='' variant='top' src={jim} />
					</Col>
					{/* DESKTOP VIEW */}
					<Col
						xs={12}
						md={6}
						className='pe-4 d-flex align-items-center'>
						{' '}
						<Card.Img
							className='d-block d-md-none'
							variant='top'
							src={jim}
						/>
						<Card
							className='rounded-0 border-0 bg-white  py-auto d-block d-sm-none'
							style={{ width: '100%' }}>
							<Card.Body className='mx-auto text-center'>
								<Booking />
							</Card.Body>
						</Card>
						<Card
							className='rounded-0  border-0 bg-white py-auto d-none d-md-block'
							style={{ width: '100%' }}>
							<Card.Body
								className='mx-auto  bg-primary-green rounded-2 text-center'
								style={{ width: '300px' }}>
								<Booking />
							</Card.Body>
						</Card>
					</Col>
					{/* MOBILE VIEW */}
					<Col xs={12} md={6} className='pe-4'>
						{' '}
						{/* <Card.Img
							className='d-block d--none'
							variant='top'
							src={jim}
						/> */}
						<Card
							className='rounded-0 border-0 bg-white  py-auto d-block d-md-none'
							style={{ width: '100%' }}>
							<Card.Body className='mx-auto text-center'>
								<Booking />
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Card>
		</Container>
	);
};

export default Calendar;

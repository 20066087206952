import React from 'react';

// react imports

// icon imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faInstagram,
	faYoutube,
	faFacebook,
	faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

// asset imports

import { Col, Row, Card, Container } from 'react-bootstrap';

const NavbarBottom = () => {
	return (
		<Container className=' border-top pt-3' sticky='bottom' expand=''>
			<Row className=''>
				<Col xs={12}>
					<div className='mx-5 p-1 text-muted text-center small'>
						Phone: (904) 337-9172
					</div>
				</Col>
				<Col xs={12}>
					<div className='mx-5 p-3  text-center text-muted small'>
						Copyright &copy; {new Date().getFullYear()}
						&nbsp;James David Presents | All Rights Reserved | site
						by{' '}
						<a href='https://anthonyapps.com/'>anthonyapps.com</a>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default NavbarBottom;

import React from 'react';
import { Image, Row, Col, Container, Card } from 'react-bootstrap';

import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri';
import MomentsCarousel from '../Carousel/MomentsCarousel';

const NocateeCard = () => {
	return (
		<Container className='g-0 p-x' fluid>
			<Card className='border-0 rounded-0   '>
				<Row>
					<Col xs={12} md={6}>
						{' '}
						<div className='d-block d-md-none'>
							<MomentsCarousel />
						</div>
						<Card.Body className='p-3  '>
							<Card.Title className='display-6 py-2 text-center fw-lighter text-green bg-transparent'>
								Moments you'll cherish!
							</Card.Title>
							<Card.Text className='blockquote card-body text-start p-3 bg-transparent'>
								<div className='position-relative'>
									<span className='fs-3 d-flex justify-content-start  '>
										<RiDoubleQuotesL />
									</span>{' '}
									<br />
									<div className='fs-6 fw-lighter p-0 m-0 '>
										Dear James, <br /> <br />
										Another amazing performance from you, as
										always. I cannot believe at has been a
										year that you have been wowing us with
										your talent. Thank you so much for
										walking into our lives, to our residents
										lives to share your joy of singing,
										passion for music that brings smile to
										all faces every time you visit. It has
										been absolute pleasure to work with you,
										we are looking forward to see you soon.
										<br />
										Thank you, with love!
										<br />
									</div>{' '}
									<br />
									<br />
									<span className='fs-4 d-flex justify-content-end '>
										<RiDoubleQuotesR />
									</span>{' '}
								</div>

								<footer className='fs-6'>
									<small className=''>
										- Jana Alexander <br /> and all
										Residents of Starling Nocatee Assisted
										Living and Memory Care
										<br />
										<br />
										<cite title='Source Title'>
											Director of Engagement, Nocatee
											Assisted Living & Memory Care
										</cite>
									</small>
								</footer>
							</Card.Text>
						</Card.Body>
					</Col>
					<Col xs={12} md={6}>
						<Card.Body
							className='text-white d-none d-md-block'
							style={{
								height: '100%',
							}}>
							<MomentsCarousel />
						</Card.Body>
					</Col>
				</Row>
			</Card>
		</Container>
	);
};

export default NocateeCard;

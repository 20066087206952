import { React, useEffect, useState } from 'react';

import './App.css';

import NavbarTop from '../src/components/Nav/NavbarTop';

import NavbarBottom from './components/Nav/NavbarBottom';
// import NocateeCard from './components/NocateeCard/NocateeCard';

// import CarouselComp from './components/Carousel/CarouselComp';
// import Hero from './components/Hero/Hero';
// import VideoComponent from './components/Video/VideoComponent';
// import About from './components/About/About';
// import Offerings from './components/Offerings/Offerings';

// import Spacer from './components/Spacer/Spacer';

// import Calendar from './components/Calendar/Calendar';
import Home from './pages/Home';
import { Route, BrowserRouter, Routes } from 'react-router-dom';

import ScrollToTopButton from './components/ScrollUp/ScrollToButton';
import ScrollToTop from './components/ScrollUp/ScrollToTop';

import { Helmet } from 'react-helmet';

function App() {
	useEffect(() => {
		window.history.scrollRestoration = 'manual';
	}, []);
	return (
		<BrowserRouter>
			<Helmet>
				<meta charset='utf-8' />
				<link rel='icon' href='%PUBLIC_URL%/favicon.ico' />
				<meta
					name='viewport'
					content='width=device-width, initial-scale=1'
				/>
				<meta name='theme-color' content='#000000' />
				<title>From NYC | James David Presents</title>
				<meta name='description' content='The Best in Entertainment' />
				<meta
					name='keywords'
					content='NYC, New York, Singer, Ponte Vedra, Jacksonville, Weddings, Wedding Singer, Country Clubs, Retirement Communities, Crooner'
				/>
				<meta
					property='og:image'
					content='%PUBLIC_URL%/share-img.jpg'
				/>
			</Helmet>

			<ScrollToTop></ScrollToTop>
			<NavbarTop />
			<Home />
			<ScrollToTopButton />
			<NavbarBottom />
		</BrowserRouter>
	);
}

export default App;

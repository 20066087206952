import Carousel from 'react-bootstrap/Carousel';
import car1 from '../../assets/car1.png';
import car2 from '../../assets/car2.png';
import car3 from '../../assets/car3.png';
import car4 from '../../assets/car4.png';

function MomentsCarousel() {
	return (
		<Carousel controls={false} indicators={false} interval='1500' fade>
			<Carousel.Item>
				<img className='d-block w-100' src={car1} alt='First slide' />
			</Carousel.Item>
			<Carousel.Item>
				<img className='d-block w-100' src={car2} alt='Second slide' />
			</Carousel.Item>
			<Carousel.Item>
				<img className='d-block w-100' src={car3} alt='Second slide' />
			</Carousel.Item>
			<Carousel.Item>
				<img className='d-block w-100' src={car4} alt='Third slide' />
			</Carousel.Item>
		</Carousel>
	);
}

export default MomentsCarousel;

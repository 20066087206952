import React from 'react';
import { Image, Row, Col, Container, Card } from 'react-bootstrap';
import nyc from '../../assets/nyc.png';

const About = () => {
	return (
		<Container className='g-0 px-0 pt-5 bg-white' id='about' fluid>
			<Card className='border-0  rounded-0 pt-5 '>
				<Row className=' bg-primary-green'>
					<Col xs={12} md={6} className='bg-nyc'>
						<Card.Body
							className=' text-white m-0 d-none d-md-block '
							style={{
								height: '100%',
							}}></Card.Body>
					</Col>
					<Col xs={12} md={6} className=''>
						{' '}
						<Card.Img
							className='d-block d-md-none'
							variant='top'
							src={nyc}
						/>
						<Card.Body className='bg-white'>
							<Card.Title className='display-6 text-center p-3 fw-lighter text-green'>
								Direct from NYC
							</Card.Title>
							<Card.Text className='fs-6'>
								<span className='fs-5 fw-light'>
									James David
								</span>{' '}
								studied Theatre and Music at Kutztown University
								in Pennsylvania. He started his professional
								music career as an entertainer for Fancy Pants
								Productions in 1998 in several productions at
								Dorney Park, where he met his wife and won the
								2000 IAPPA (International Association of
								Amusement Parks and Attractions) for best
								vocalist. His next stop was a resindency with
								Carnival Cruise Lines as the featured male
								vocalist in their production shows and as
								headline entertainer in his own show in the
								Criterion Lounge. James moved around from Las
								Vegas, L.A. and Philadelphia involved in
								different productions before landing in NYC in
								2006 where he remained until 2020. His first gig
								in NYC was at The Metropolitan Room, the famed
								cabaret mainstay. James joined the cast at
								Ellen’s Stardust Diner from 2008 and contributed
								as a vocalist for 10 years. While in New York,
								he performed as a member of Disney in Concert, a
								world touring symphonic production of the music
								of Walt Disney. He co-founded NYC trio The Copa
								Boys, which debuted at the world-famous Iridium
								Jazz Club and went on to and perform at
								Birdland, The Duplex, The Metropolitan Room and
								54 Below. The Copa Boys then teamed up with Mike
								Berkowitz, resident conductor of the Santa Rosa
								California symphony who included them as
								featured vocalists for multiple symphony
								programs. James continues to collaborate with
								famed pianist/arranger John Colianni, a former
								member of Lionel Hampton’s band and pianist for
								jazz legend Mel Torme. James moved to Ponte
								Vedra FL in May of 2020 at the beginning of the
								pandemic. James is quicly making a name for
								himself locally as Jacksonville’s #1 crooner!
							</Card.Text>
						</Card.Body>
					</Col>
				</Row>
			</Card>
		</Container>
	);
};

export default About;

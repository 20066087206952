import React from 'react';
import { Card, Container, Image, Row, Col } from 'react-bootstrap';
import ReactPlayer from 'react-player';

const VideoComponent = () => {
	return (
		<div className='player-wrapper pt-5 bg-white' id='media'>
			<ReactPlayer
				width='100%'
				height='800px'
				url='https://youtu.be/8WIhsh--FLo'
			/>
		</div>
	);
};

export default VideoComponent;

import { React, useState } from 'react';

import { Button, Modal, Card, CloseButton } from 'react-bootstrap';

const Booking = (props) => {
	const values = [true];
	const [fullscreen, setFullscreen] = useState(true);
	const [show, setShow] = useState(false);

	function handleShow(breakpoint) {
		setFullscreen(breakpoint);
		setShow(true);
	}
	return (
		<>
			{values.map((v, idx) => (
				<Button
					key={idx}
					className='bg-primary-green'
					variant=''
					size='md'
					onClick={() => handleShow(v)}>
					<span className='fs-4 fw-lighter text-white'>BOOK NOW</span>
					{typeof v === 'string' && `below ${v.split('-')[0]}`}
				</Button>
			))}
			<Modal
				show={show}
				fullscreen={fullscreen}
				onHide={() => setShow(false)}>
				<Modal.Header
					className='bg-primary-green '
					variant='dark'
					closeButton
					closeVariant='white'>
					<Modal.Title className='text-white fs-3 fw-light'>
						Inquiries
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className='bg-transparent p-0 '>
					<iframe
						src='https://forms.gle/Hr6QZxj7jdSLWLGz8'
						width='100%'
						height='4000px'
						frameBorder='0'></iframe>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Booking;
